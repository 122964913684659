<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.newsId ? $t('common.form.title.editNews')  + ' (' + this.payload.newsId + ')' : $t('common.form.title.createNews')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <template>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.news.upload_file') }}
                                </template>
                                <b-form-file @change="fileChanged" accept=".jpg,.png,.jpeg,.bmp"></b-form-file>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col cols="6" v-if="form.file" :data-url="getImageBlobUrl(form.file)">
                            <img v-if="images[form.file]"
                                 :src="images[form.file]"
                                 alt=""
                                 class="w-25">
                            <input type="hidden" name="file" :value="form.file">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                            <zw-input-group v-model="form.title"
                                            name="title"
                                            :label-prefix="labelPrefix"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-select-group v-model="form.template"
                                             :options="getNewsTemplates()"
                                             name="template"
                                             :label-prefix="labelPrefix"
                                             text-field="name"
                                             value-field="id"
                                             @change="templateSelected"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-ckeditor v-model="form.description"></zw-ckeditor>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-input-group v-model="form.link"
                                            name="link"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-new-date-group v-model="form.expire_date"
                                               name="expire_date"
                                               :label-prefix="labelPrefix"
                                               format="YYYY-MM-DD"
                            ></zw-new-date-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="3"></b-col>
                        <b-col sm="6">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                        <b-col sm="3"></b-col>
                    </b-row>
                </template>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import axios from "axios";
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    name: 'news-modal',
    mixins: [commonSave],
    components: {ZwNewDateGroup},
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            form: {},
            labelPrefix: 'common.label.news.',
            response: null,
            images: {}
        }
    },
    mounted() {
    },
    computed: {},
    methods: {
        ...mapGetters('News', ['getThenew']),
        ...mapGetters('NewsTemplate', ['getNewsTemplates']),
        shown() {
            this.loading = false
            this.$store.dispatch('NewsTemplate/fetchTemplates')
            this.$store.dispatch('News/fetchThenew', this.payload.newsId)
                .then(() => {
                    this.form = this.getThenew()
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        getImageBlobUrl(name) {
            if (typeof this.images[name] == 'undefined') {
                axios.get(window.apiUrl + '/download/news/' + name)
                    .then(response => {
                        function s2ab(s) {
                            let buf = new ArrayBuffer(s.length);
                            let view = new Uint8Array(buf);
                            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                            return buf;
                        }

                        const blob = new Blob([s2ab(atob(response.data.content))], {type: response.data.type});

                        this.$set(this.images, name, window.URL.createObjectURL(blob))
                    })
            }
        },
        fileChanged(event) {
            this.loading = true
            this.form.uploadedFile = event.target.files[0]
            let formData = new FormData();
            formData.append('file', this.form.uploadedFile);
            formData.append('id', this.form.id);

            axios.post(window.apiUrl + '/upload-news', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                'skip_loading': true,
            })
                .then(result => {
                    this.response = result.data
                    this.form.file = this.response.fileName;
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('News/saveThenew', formData)
                        .then((response) => {
                            this.commonAfterSave(response, this.sidebar)
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false

                                this.$refs['observer'].setErrors(errors)
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        templateSelected() {
            const template = this.getNewsTemplates().find(template => template.id == this.form.template)

            if (template) {
                this.$set(this.form, 'title', template.subject)
                this.$set(this.form, 'link', template.link)
                this.$set(this.form, 'description', template.body)
            }
        },
    }
}
</script>